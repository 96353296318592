
/*=========================================
=               06. Footer Css            =
===========================================*/

.contact-text {
    p {
        margin-bottom: 0;
    }
}

/*=====  End of footer ======*/
