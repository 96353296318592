
/*=============================================
=            04. container Css            =
=============================================*/

.container-fluid{
    &--cp-150{
        @media (min-width: 1600px){
            padding: 0 150px !important;
        }
        @media (min-width: 1600px) and (max-width: 1664px){
            padding: 0 110px !important;
        }
        @media (min-width: 1200px){
            padding: 0 50px;
        }
    }
    &--cp-140{
        @media (min-width: 1700px){
            padding: 0 140px !important;
        }
        @media (min-width: 1700px)  and (max-width: 1663px){
            padding: 0 100px !important;
        }
        @media (min-width: 1600px) and (max-width: 1664px){
            padding: 0 30px !important;
        }
        @media (min-width: 1200px){
            padding: 0 30px;
        }
    }
    &--cp-120{
        @media (min-width: 1600px){
            padding: 0 120px !important;
        }
        @media (min-width: 1600px) and (max-width: 1664px){
            padding: 0 110px !important;
        }
        @media (min-width: 1200px){
            padding: 0 50px;
        }
    }
    &--cp-80{
        @media (min-width: 1600px){
            padding: 0 80px !important;
        }
        @media (min-width: 1600px) and (max-width: 1664px){
            padding: 0 30px !important;
        }
        @media (min-width: 1200px){
            padding: 0 30px;
        }
    }
    &--cp-60{
        padding: 0 60px;
        @media #{$large-mobile}{
            padding: 0 15px;
        }
    }
    &--cp-30{
        padding: 0 30px;
        @media #{$large-mobile}{
            padding: 0 15px;
        }
    }
}
@media (min-width: 1200px){
    .container {
        max-width: 1200px;
    }
}
.container_fl {
    padding-left: 15px;
    padding-right: 15px;
}

/*=====  End of container  ======*/
