/*=============================================
=              05. Header Css            =
=============================================*/

.header-area{
    & .is-sticky{
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        animation: .95s ease-in-out 0s normal none 1 running fadeInDown;
        z-index: 999;
        transition: $transition--default;
        box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.1);
        background-color: #fff;   
    }
}
.absolute-header {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100px;
    display: flex;
    align-items: center;
    z-index: 3;
    @media #{$small-mobile}{
        height: 80px;
    }
}
.header {
    /* logo */
    
    &__logo{
        @media #{$tablet-device, $large-mobile}{
            & .dark-logo {
                display: inherit;
            }
            & .light-logo {
                display: none;
            }
        }
        
    }
}

